import React, { MouseEvent, FC, memo } from 'react';
import { withFirebase } from './../../Firebase/context';
import Firebase from './../../Firebase';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
	firebase: Firebase;
	user: any;
}

const SignOutButton: FC<Props> = ({ firebase, user, history }) => {
	const handleSignOut = (e: MouseEvent) => {
		e.preventDefault();
		firebase.auth.signOut();
		history.push('/');
	};
	return (
		<div
			className='btn btn-warning text-white btn-sm'
			onClick={handleSignOut}
		>
			You are {user.displayName}!
			<br />
			<small>(Sign Out?)</small>
		</div>
	);
};

export default memo(withRouter(withFirebase(SignOutButton)));
