import { Dispatch, SetStateAction, Component } from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
};

class Firebase extends Component {
	auth: any;
	db: any;
	dbFunc: any;
	storage: any;

	constructor(props: any) {
		super(props);
		app.initializeApp(firebaseConfig);
		this.auth = app.auth();
		this.db = app.firestore();
		this.dbFunc = app.firestore;
		this.storage = app.storage();
	}

	handleAuthStateChanged = (setUser: Dispatch<SetStateAction<app.User>>) => {
		return this.auth.onAuthStateChanged((user: app.User) => {
			setUser(user);
		});
	};

	doRegister = async (
		email: string,
		password: string,
		name: string,
		setError: Dispatch<SetStateAction<string | null>>,
		setSuccess: Dispatch<SetStateAction<boolean>>
	) => {
		await this.auth
			.createUserWithEmailAndPassword(email, password)
			.then((res: any) => {
				res.user
					.updateProfile({ displayName: name })
					.then(() => setSuccess(true))
					.catch((e: Error) => setError(e.message));
			})
			.catch((e: Error) => setError(e.message));
	};

	doSignIn = async (email: string, password: string) => {
		await this.auth.signInWithEmailAndPassword(email, password).then(() => {
			window.localStorage.setItem('emailForSignIn', email);
		});
	};

	doSendPasswordResetEmail = async (email: string) => {
		await this.auth.sendPasswordResetEmail(email);
	};

	doAddSupport = async (
		thumb: File,
		url: string,
		urlTitle: string,
		imageAltText: string,
		setError: Dispatch<SetStateAction<string | null>>,
		setSuccess: Dispatch<SetStateAction<boolean>>
	) => {
		await this.storage
			.ref()
			.child('supports/' + urlTitle)
			.put(thumb)
			.then((res: firebase.storage.UploadTaskSnapshot) => {
				res.ref.getDownloadURL().then((thumbUrl) => {
					this.db
						.collection('supports')
						.add({
							thumbnail: thumbUrl,
							link: url,
							imageAltText: imageAltText,
							urlTitle: urlTitle,
						})
						.then(() => setSuccess(true))
						.catch(() =>
							setError(
								"The image was uploaded to the storage server, but it didn't link properly to the database. This is a moment to take a picture of this error, text Cal and then try again!"
							)
						);
				});
			})
			.catch(() =>
				setError(
					'There was a problem whilst uploading the image file to our storage server. Please try again.'
				)
			);
	};

	doUploadFilesAndAddEpisodeToDataBase = async (
		resetFields: () => void,
		setUploadingThumb: Dispatch<SetStateAction<boolean>>,
		setUploadingTranscript: Dispatch<SetStateAction<boolean>>,
		setError: Dispatch<SetStateAction<string | null>>,
		setSuccess: Dispatch<SetStateAction<string | null>>,
		thumbAltText: string,
		title: string,
		description: string,
		furtherReading: {
			type: string;
			data: string;
			linkTitle?: string;
		}[],
		guestsArray: { name: string; url: string }[],
		thumbnail: File | null,
		listenURLs: { platform: string; url: string }[],
		transcript?: File
	) => {
		setUploadingThumb(true);
		this.storage
			.ref()
			.child('thumbs/' + encodeURIComponent(decodeURIComponent(title)))
			.put(thumbnail)
			.then((snap: firebase.storage.UploadTaskSnapshot) => {
				snap.ref
					.getDownloadURL()
					.then((downloadURL: string) => {
						const thumbURL = downloadURL;
						setUploadingThumb(false);
						if (transcript) {
							setUploadingTranscript(true);
							this.storage
								.ref()
								.child(
									'transcripts/' +
										encodeURIComponent(
											decodeURIComponent(title)
										)
								)
								.put(transcript)
								.then(
									(
										transSnap: firebase.storage.UploadTaskSnapshot
									) => {
										transSnap.ref
											.getDownloadURL()
											.then((downloadURL: string) => {
												const transURL = downloadURL;
												this.db
													.collection('episodes')
													.add({
														title: encodeURIComponent(
															decodeURIComponent(
																title
															)
														),
														description: description,
														furtherReading: furtherReading,
														guestsArray: guestsArray,
														thumbURL: thumbURL,
														thumbAltText: thumbAltText,
														transURL: transURL,
														listenURLs: listenURLs,
														added: this.dbFunc.FieldValue.serverTimestamp(),
													})
													.then(() => {
														setSuccess(
															'The episode has been successfully added to the database.'
														);
														setError(null);
														resetFields();
													})
													.catch((e: Error) =>
														setError(e.message)
													);
											});
									}
								)
								.catch((e: Error) => setError(e.message));
						} else {
							this.db
								.collection('episodes')
								.add({
									title: encodeURIComponent(
										decodeURIComponent(title)
									),
									description: description,
									furtherReading: furtherReading,
									guestsArray: guestsArray,
									thumbURL: thumbURL,
									thumbAltText: thumbAltText,
									listenURLs: listenURLs,
									added: this.dbFunc.FieldValue.serverTimestamp(),
								})
								.then(() => {
									setSuccess(
										'The episode has been successfully added to the database.'
									);
									setError(null);
									resetFields();
								})
								.catch((e: Error) => setError(e.message));
						}
					})
					.catch((e: Error) => setError(e.message));
			});
	};

	doUpdateFile = async (
		type: string,
		episodeToEdit: firebase.firestore.DocumentSnapshot,
		file: File,
		setError: Dispatch<SetStateAction<string | null>>,
		setUploading: Dispatch<SetStateAction<boolean>>
	) => {
		setUploading(true);
		if (type === 'thumb') {
			this.storage
				.ref()
				.child(
					'thumbs/' +
						encodeURIComponent(
							decodeURIComponent(episodeToEdit.data()?.title)
						)
				)
				.put(file)
				.then((res: firebase.storage.UploadTaskSnapshot) => {
					res.ref.getDownloadURL().then((url) => {
						this.db
							.collection('episodes')
							.doc(episodeToEdit.id)
							.update({ thumbURL: url })
							.then(() => {
								setUploading(false);
								return true;
							})
							.catch(() => {
								setError(
									'The thumbnail was uploaded to the database, but it didn\'t link properly to the episode... Try again, but like... This is a, "text Cal", kind of issue.'
								);
								return false;
							});
					});
				})
				.catch(() => {
					setError(
						'There was an error uploading the thumbnail to the database! You should try again. Maybe try selecting it again?'
					);
					return false;
				});
		} else if (type === 'transcript') {
			this.storage
				.ref()
				.child(
					'transcripts/' +
						encodeURIComponent(
							decodeURIComponent(episodeToEdit.data()?.title)
						)
				)
				.put(file)
				.then((res: firebase.storage.UploadTaskSnapshot) => {
					res.ref.getDownloadURL().then((url) => {
						this.db
							.collection('episodes')
							.doc(episodeToEdit.id)
							.update({ transURL: url })
							.then(() => {
								setUploading(false);
								return true;
							})
							.catch(() => {
								setError(
									'The transcript was uploaded to the database, but it didn\'t link properly to the episode... Try again, but like... This is a, "text Cal", kind of issue.'
								);
								return false;
							});
					});
				})
				.catch(() => {
					setError(
						'There was an error uploading the transcript to the database! You should try again. Maybe try selecting it again?'
					);
					return false;
				});
		}
	};

	doUpdateEpisode = async (
		episodeToUpdate: app.firestore.DocumentSnapshot | null,
		setUploadingThumb: Dispatch<SetStateAction<boolean>>,
		setUploadingTranscript: Dispatch<SetStateAction<boolean>>,
		setError: Dispatch<SetStateAction<string | null>>,
		setSuccess: Dispatch<SetStateAction<string | null>>,
		thumbAltText: string,
		title: string,
		description: string,
		furtherReading: {
			type: string;
			data: string;
			linkTitle?: string;
		}[],
		guestsArray: { name: string; url: string }[],
		listenURLs: { platform: string; url: string }[],
		thumbnail?: File | null,
		transcript?: File | null
	) => {
		if (!thumbnail && !transcript) {
			await this.db
				.collection('episodes')
				.doc(episodeToUpdate?.id)
				.update({
					thumbAltText: thumbAltText,
					title: encodeURIComponent(decodeURIComponent(title)),
					description: description,
					furtherReading: furtherReading,
					guestsArray: guestsArray,
					listenURLs: listenURLs,
				})
				.then(() =>
					setSuccess('You have successfully updated this episode!')
				)
				.catch((e: Error) => setError(e.message));
		} else if (thumbnail && !transcript) {
			if (episodeToUpdate) {
				this.doUpdateFile(
					'thumb',
					episodeToUpdate,
					thumbnail,
					setError,
					setUploadingThumb
				).then(() => {
					this.db
						.collection('episodes')
						.doc(episodeToUpdate?.id)
						.update({
							thumbAltText: thumbAltText,
							title: encodeURIComponent(
								decodeURIComponent(title)
							),
							description: description,
							furtherReading: furtherReading,
							guestsArray: guestsArray,
							listenURLs: listenURLs,
						})
						.then(() =>
							setSuccess(
								'You have successfully updated this episode!'
							)
						)
						.catch((e: Error) => setError(e.message));
				});
			}
		} else if (transcript && !thumbnail) {
			if (episodeToUpdate) {
				this.doUpdateFile(
					'transcript',
					episodeToUpdate,
					transcript,
					setError,
					setUploadingTranscript
				).then(() => {
					this.db
						.collection('episodes')
						.doc(episodeToUpdate?.id)
						.update({
							thumbAltText: thumbAltText,
							title: encodeURIComponent(
								decodeURIComponent(title)
							),
							description: description,
							furtherReading: furtherReading,
							guestsArray: guestsArray,
							listenURLs: listenURLs,
						})
						.then(() =>
							setSuccess(
								'You have successfully updated this episode!'
							)
						)
						.catch((e: Error) => setError(e.message));
				});
			}
		} else if (thumbnail && transcript) {
			if (episodeToUpdate) {
				this.doUpdateFile(
					'thumb',
					episodeToUpdate,
					thumbnail,
					setError,
					setUploadingThumb
				).then(() => {
					this.doUpdateFile(
						'transcript',
						episodeToUpdate,
						transcript,
						setError,
						setUploadingThumb
					).then(() => {
						this.db
							.collection('episodes')
							.doc(episodeToUpdate?.id)
							.update({
								thumbAltText: thumbAltText,
								title: encodeURIComponent(
									decodeURIComponent(title)
								),
								description: description,
								furtherReading: furtherReading,
								guestsArray: guestsArray,
								listenURLs: listenURLs,
							})
							.then(() =>
								setSuccess(
									'You have successfully updated this episode!'
								)
							)
							.catch((e: Error) => setError(e.message));
					});
				});
			}
		}
	};
}

export default Firebase;
