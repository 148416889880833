import React from 'react';
import ReactDOM from 'react-dom';
import './scss/custom.scss';
import App from './App';

import { HashRouter as Router } from 'react-router-dom';

import { FirebaseContext } from './components/Firebase';
import Firebase from './components/Firebase';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<FirebaseContext.Provider value={new Firebase('yo')}>
				<App />
			</FirebaseContext.Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
