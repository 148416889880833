import React, { FC, memo, ReactFragment } from 'react';

const Spinner: FC<{ colour?: string; children: ReactFragment }> = ({
	colour,
	children,
}) => {
	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12 text-center'>
					<div
						className={
							!colour
								? 'spinner-border'
								: 'spinner-border text-' + colour
						}
						role='status'
					>
						{!children ? (
							<span className='sr-only'>Loading...</span>
						) : (
							<span className='sr-only'>{children}</span>
						)}
					</div>
				</div>
			</div>
			{children && (
				<div className='row'>
					<div className='col-12 text-center'>{children}</div>
				</div>
			)}
		</div>
	);
};

export default memo(Spinner);
