import React, { ReactFragment, FC, memo } from 'react';

const MainCard: FC<{
	PageHeader: ReactFragment;
	children: ReactFragment;
	PageFooter?: ReactFragment;
	sizing?: string;
	about?: boolean;
}> = ({ PageHeader, PageFooter, sizing, about, children }) => {
	return (
		<div className='mainCard'>
			<div className='container'>
				<div className='row'>
					<div className={!sizing ? 'col-12' : sizing}>
						<div className='card bg-info my-5 w-100'>
							<div
								className={
									about
										? 'card-header text-center bg-primary pb-0'
										: 'card-header text-center bg-primary'
								}
							>
								{PageHeader}
							</div>
							<div className='card-body' id='allEpisodesCard'>
								{children}
							</div>
							<div className='card-footer bg-primary text-center'>
								{PageFooter && PageFooter}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(MainCard);
