import React from 'react';
import { createContext, FC } from 'react';

const FirebaseContext = createContext<any|null>(null);

export const withFirebase = (Component: FC<any>) => (props: any) => (
	<FirebaseContext.Consumer>
		{(firebase) => <Component {...props} firebase={firebase} />}
	</FirebaseContext.Consumer>
);
export default FirebaseContext;
