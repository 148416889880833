import React, { FC, memo } from 'react';

import logoNoText from '../../images/Not_My_Monkeys_Podcast_Logo_NoText_800x800.png';
import logoNoTextSmall from '../../images/Not_My_Monkeys_Podcast_Logo_NoText_150x150.png';

const Logo: FC<{ size?: number; center?: boolean }> = ({ size }) => {
	return (
		<img
			src={size ? logoNoTextSmall : logoNoText}
			width={size ? size + 'em' : undefined}
			height={size ? size + 'em' : undefined}
			className='img-fluid mx-auto'
			alt="The 'Not My Monkeys' podcast logo. A caricature of an inquisitive looking monkey. The monkey's head has a large hole, exposing his brain and pouring out of it are many circus-themed items. These items include tents, bunting, balloons, ladders, juggling balls, unicycles, bow-ties, bowler hats and trumpets! Above the monkey, lies the name of the podcast written as part of an audio wave."
		/>
	);
};

export default memo(Logo);
