import React, { FC, memo } from 'react';
import Logo from '../Logo';
import { routes } from '../../../routes';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

const Header: FC<RouteComponentProps> = ({ history }) => {
	return (
		<nav
			className='navbar navbar-expand-lg navbar-light bg-info'
			id='header'
		>
			<a className='navbar-brand' href='/'>
				<Logo size={80} />
			</a>
			<button
				className='navbar-toggler ml-auto'
				type='button'
				data-toggle='collapse'
				data-target='#collapsingNavbar'
				aria-controls='collapsingNavbar'
				aria-expanded='false'
				aria-label='Toggle navigation'
			>
				<span className='navbar-toggler-icon'></span>
			</button>
			<div className='collapse navbar-collapse' id='collapsingNavbar'>
				<ul className='navbar-nav mx-auto'>
					{routes.map(
						(route) =>
							!route.hidden && (
								<li className='nav-item' key={route.title}>
									<NavLink
										exact
										to={route.path}
										className='nav-link text-light text-center'
										activeClassName='active'
									>
										{route.title + ' '}
										{history.location.pathname ===
											route.path && (
											<span className='sr-only'>
												(current)
											</span>
										)}
									</NavLink>
								</li>
							)
					)}
				</ul>
			</div>
		</nav>
	);
};

export default memo(withRouter(Header));
