import React, { useEffect, useState, FC, memo, Suspense } from 'react';
import {
	Route,
	withRouter,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';

import Header from './components/General/Header/Header';
import Footer from './components/General/Footer/Footer';

import { routes } from './routes';

import UserContext from './components/User';

import Firebase from './components/Firebase/index';
import { withFirebase } from './components/Firebase/context';
import Spinner from './components/General/Spinner';
import MainCard from './components/General/MainCard';

interface Props extends RouteComponentProps {
	firebase: Firebase;
}

const App: FC<Props> = ({ firebase }) => {
	const [user, setUser] = useState<any>(null);

	useEffect(() => {
		if (firebase) {
			return firebase.handleAuthStateChanged(setUser);
		}
	}, [firebase]);

	return (
		<UserContext.Provider value={user}>
			<div
				className='App'
				style={{
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Header />
				<div
					id='main'
					className='text-light'
					style={{
						flex: '1',
						backgroundColor: '#fff',
						backgroundSize: '150px 150px',
					}}
				>
					<Suspense
						fallback={
							<MainCard PageHeader={<div />}>
								<Spinner>Loading page...</Spinner>
							</MainCard>
						}
					>
						<Switch>
							{routes.map(({ path, title, Component }) => (
								<Route
									exact
									key={title}
									path={path}
									component={Component}
								/>
							))}
						</Switch>
					</Suspense>
				</div>
				<Footer />
			</div>
		</UserContext.Provider>
	);
};

export default memo(withRouter(withFirebase(App)));
