import React, { createContext, ComponentProps, FC } from 'react';

const UserContext = createContext(null);

export const withAuth = (Component: FC<any>) => (
	props: ComponentProps<any>
) => {
	return (
		<UserContext.Consumer>
			{(user) => <Component {...props} user={user} />}
		</UserContext.Consumer>
	);
};

export default UserContext;
