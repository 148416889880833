import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const AllEpisodes = lazy(() => import('./pages/AllEpisodes'));
const Login = lazy(() => import('./pages/Login'));
const Episode = lazy(() => import('./pages/Episode'));
const About = lazy(() => import('./pages/About'));
const GetInvolved = lazy(() => import('./pages/GetInvolved'));

export const routes = [
	{
		title: 'Home',
		path: '/',
		Component: Home,
		hidden: false,
	},
	{
		title: 'Episodes',
		path: '/episodes',
		Component: AllEpisodes,
		hidden: false,
	},
	{
		title: 'Episode',
		path: '/episodes/:paramEpId',
		Component: Episode,
		hidden: true,
	},
	{
		title: 'About',
		path: '/about',
		Component: About,
		hidden: false,
	},
	{
		title: 'Get Involved',
		path: '/getinvolved',
		Component: GetInvolved,
		hidden: false,
	},
	{
		title: 'Contact',
		path: '/contact',
		Component: Contact,
		hidden: false,
	},
	{
		title: 'I Am',
		path: '/login',
		Component: Login,
		hidden: true,
	},
];
