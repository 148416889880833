import React, { FC, memo } from 'react';

import patron from '../../../images/Patron_Badge.png';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { withAuth } from '../../User';
import SignOutButton from './../Header/SignOutButton';

interface Props extends RouteComponentProps {
	user: any;
}

const Footer: FC<Props> = ({ history, user }) => {
	return (
		<div id='footer' className='bg-info pt-2'>
			<div className='container'>
				<div className='row align-items-center justify-content-around'>
					<div className='col-6 col-md-2'>
						<ul className='list-unstyled text-center'>
							<li className='nav-item'>
								<a
									href='/privacypolicy.htm'
									target='_blank'
									rel='noreferrer noopener'
									className='nav-link text-light'
								>
									Privacy Policy
								</a>
							</li>
							<li className='nav-item'>
								<a
									href='/termsofservice.htm'
									target='_blank'
									rel='noreferrer noopener'
									className='nav-link text-light'
								>
									Terms of Service
								</a>
							</li>
							<li className='nav-item'>
								<a
									href='/sitemap.xml'
									target='_blank'
									rel='noreferrer noopener'
									className='nav-link text-light'
								>
									Sitemap
								</a>
							</li>
						</ul>
					</div>
					<div className='col-6 col-md-2'>
						<ul className='list-unstyled text-center'>
							<li className='nav-item'>
								<a
									href='https://www.patreon.com/Notmymonkeyspodcast'
									className='nav-link text-light'
								>
									<img
										src={patron}
										alt='Become a Patron to Not My Monkeys!'
										className='img-fluid text-center'
									/>
								</a>
							</li>
							<li className='nav-item'>
								<a
									href='mailto:info@calcourtney.net?subject=I Have Found a Bug in the Not My Monkeys Podcast Website.'
									title='Email the Web Master!'
									className='nav-link text-light'
								>
									Report a Bug
								</a>
							</li>
							<li className='nav-item'>
								{user ? (
									<SignOutButton user={user} />
								) : (
									<NavLink
										to='/login'
										title='Login'
										className='nav-link text-light footerLogin'
									>
										Login
									</NavLink>
								)}
							</li>
						</ul>
					</div>
				</div>
				{(history.location.pathname === '/getinvolved' ||
					history.location.pathname === '/') && (
					<div className='row'>
						<div className='col text-center text-white'>
							<a
								target='_blank'
								href='https://icons8.com/icons/set/facebook-new'
								rel='noopener noreferrer'
							>
								Facebook{' '}
							</a>
							and{' '}
							<a
								target='_blank'
								href='https://icons8.com/icons/set/instagram-new'
								rel='noopener noreferrer'
							>
								Instagram{' '}
							</a>{' '}
							icons by{' '}
							<a
								target='_blank'
								href='https://icons8.com'
								rel='noopener noreferrer'
							>
								Icons8
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(withRouter(withAuth(Footer)));
